import { makeStyles } from '@material-ui/core';

export const useLandingPageStyles = makeStyles(() => ({
  backgroundContainer: {
    // Position Properties
    width: '100vw',
    height: '100vh',
    // Styling Property
    backgroundColor: 'orange',
    // Layout Property
    display: 'grid'
    // stops the user to press and hold the div and move it
    // touchAction: 'none'
  },

  bodyContainer: {
    // Position Properties
    width: '80%',
    height: '90%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    // Styling Properties
    backgroundColor: 'white',
    border: 'solid 1px black',
    // Aligning Property
    placeSelf: 'center',
    // Text Layout Property
    overflowWrap: 'break-word'
  },

  slideContainer: {
    backgroundColor: 'pink'
  }
}));
