import React from 'react';
import { useLandingPageStyles } from './components/styles/pageLayout';
// import imageOne from '../src/images/one.jpg';

const App: React.FC = () => {
  const { backgroundContainer, bodyContainer, slideContainer } = useLandingPageStyles();
  return (
    <div className={backgroundContainer}>
        <div className={bodyContainer}>
          <div className={slideContainer}>
            One
          </div>
        </div>
    </div>
  );
};

export default App;

/**
 * OK, the website. What do we want. Think just working for an iPhone SE for the time being and work outwards.
 *
 *
 * NOTES:
 * - I would like parallax, would it be needed?
 *    - I am considering the background element. What is to be displayed. If we
 *      were not using parallax, would it be a static image and then us
 *      manipulating the way the information is transitioned.
 *    - To consider it if parallax is not an option.
 *      - Could have it where the body transition is slowed at the end point?
 *      - Consider the background to have the body information centred. Like
 *        a picture frame.
 * - I would like a database, would it be needed?
 *    - I have to consider how is the user going to be submitting their data of
 *      are they coming and food selections.
 *    - If it were to be a QR code?
 *      - Need to figure out how to program that. How to make it unique to the
 *        user?
 *      - What happens then the user scans it?
 *        - Do they get taken to the website or straight to an input form?
 *    - If it were to be a straight weblink and password?
 *      - Again, straight to the website or input form?
 * - I got to consider touch screen, the inspect view will allow you to do the
 *    touch elements
 *    - Pretty sure that its a case of program towards touch and its backwards
 *      compatible towards mouse.
 * - The site will mainly be a scroll up and down
 * - What information to be displayed?
 *    - Possible order.
 *        - Logo
 *        - Jargon for invitation 'you have been invited too'
 *        - Location of event
 *        - Hotels in surrounding area
 *        - Menu. Do we know what it will be by xmas?
 *        - Input forms
 *          - Are you coming?
 *          - What food do you want?
 *          - Are you allergic?
 *
 * - One thing to strongly consider that if i was on the input form and i swiped
 *   away or went onto another app. Is my information saved?
 * - What happens if i did all of this and 2 weeks went by and i wanted to
 *   change the food i wanted? Is there a deadline?
 */
